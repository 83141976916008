
import { defineComponent, onMounted } from 'vue';
import DG from '2gis-maps';

export default defineComponent({
  name: 'Contacts',
  setup() {
    onMounted(() => {
      init2gisMap()
    })

    const init2gisMap = () => {
      const map = DG.map('maps', {
          'inertia': false,
          'center': [51.161294, 71.401087],
          'zoom': 17
      })

      const icon = DG.icon({
          iconUrl: require('@/assets/images/pin.svg'),
          iconSize: [36, 64],
          iconAnchor: [17, 62],
      });
      DG.marker([51.161894, 71.401187], {icon}).addTo(map);
    }

    return {
    }
  }
});
