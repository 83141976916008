<template>
  <div class="contacts">
     <div class="appContainer">
        <h1>Котакты</h1>
        <p>Alienum phaedrum torquatos nec eu, detr periculis ex, nihil expetendis in mei. Mei anperic ula euripidis hinc partem ei est. Eos ei nisl graecis, vix aperiri consequat an. Eiuslorem rea tincidunt vix at, vel pertinax sensibus id, error epicurei mea et. Mea facilisis urbanitas mode ratius id. Vis ei rationibus definiebas, eu qui purto zril laoreet. Ex error omnium interpretaris pro, alia illum ea vim. Eos ei nisl graecis, vix aperiri.</p>
        <div id="maps"></div>
     </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue';
import DG from '2gis-maps';

export default defineComponent({
  name: 'Contacts',
  setup() {
    onMounted(() => {
      init2gisMap()
    })

    const init2gisMap = () => {
      const map = DG.map('maps', {
          'inertia': false,
          'center': [51.161294, 71.401087],
          'zoom': 17
      })

      const icon = DG.icon({
          iconUrl: require('@/assets/images/pin.svg'),
          iconSize: [36, 64],
          iconAnchor: [17, 62],
      });
      DG.marker([51.161894, 71.401187], {icon}).addTo(map);
    }

    return {
    }
  }
});
</script>


<style lang="scss" scoped>
#maps {
  width: 100%;
  height: 35vw;
  @include get-media($mobile) {
    height: 100vw;
  }
}
p {
  text-align: left;
  max-width: 60%;
  font-size: 120%;
  @include get-media($mobile) {
    max-width: 100%;
  }
}
</style>
